const React = require("react");
const { useState, useEffect } = React;

const { Button, Container, Columns, Image, Progress, Section } = require("react-bulma-components");
const { InputField } = require("./helpers.js");
const { default: BlockUi } = require("react-block-ui");
const { BACKEND_URL } = require("../constant");
const { hash } = require("../functions/hash");

const images = [
  require("../../static/one.jpg"),
  require("../../static/two.jpg"),
  require("../../static/three.jpg"),
];

module.exports = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  const [voting, setVoting] = useState(false);
  const [votes, setVotes] = useState(new Array(images.length).fill(0));
  const [leftData, setLeftData] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);

  const [apikey, setApikey] = useState("");
  const [apikeyInputToggle, setApikeyInputToggle] = useState(true);
  const [loadButtonActive, setLoadButtonActive] = useState(true);
  const [delaySecond, setDelaySecond] = useState("0.5");

  const loadVoteData = async () => {
    if (apikey.length < 10) {
      alert("API Key가 너무 짧습니다.");
      return;
    }
    try {
      const res = await fetch(`${BACKEND_URL}/vote`, {
        headers: {
          "x-api-key": hash(apikey),
        },
      });
      if (res.status !== 200) {
        alert("잘못된 API Key입니다.");
        return;
      }
      const data = await res.json();
      setLeftData(data.data.filter((el) => el !== -1));
      setTotalStudents(data.data.filter((el) => el !== -1).length);
      setLoadButtonActive(false);
      setApikey("");
      setTimeout(() => {
        setLoadButtonActive(true);
      }, 5000);
      setLoading(false);
    } catch (e) {
      alert("개표 정보 로딩 실패");
      console.log(e);
      return;
    }
  };

  useEffect(() => {
    const delayMilliSec = (+delaySecond || 0.5) * 1000;
    if (!voting) {
      return undefined;
    }
    let timer;
    if (leftData.length === 0) {
      timer = setTimeout(() => {
        setVoting(false);
      }, delayMilliSec);
    } else {
      timer = setTimeout(() => {
        setLeftData(leftData.slice(1));
        const updatedVotes = votes;
        updatedVotes[leftData[0]]++;
        setVotes(updatedVotes);
      }, delayMilliSec);
    }
    return () => clearTimeout(timer);
  }, [voting, votes, leftData]);

  return (
    <Container>
      <BlockUi blocking={loading}>
        {images.map((image, idx) => (
          <Columns className="is-vcentered">
            <Columns.Column size={2}>
              <Image src={image} />
            </Columns.Column>
            <Columns.Column>
              <Progress color="info" max={totalStudents} value={votes[idx]} />
              {loading ? null : (
                <p style={{ textAlign: "center" }}>
                  {votes[idx]} / {totalStudents} = {Math.trunc((votes[idx] / totalStudents) * 100)}%
                </p>
              )}
            </Columns.Column>
          </Columns>
        ))}
        <Button color="link" loading={voting} onClick={() => setVoting(true)} pull="right">
          개표
        </Button>
      </BlockUi>
      <Section>
        <Button onClick={() => setApikeyInputToggle((v) => !v)}>관리자코드 입력</Button>
        {apikeyInputToggle && (
          <Container>
            <InputField
              label="관리자코드"
              type="text"
              placeholder="관리자코드"
              required
              value={apikey}
              onChange={(e) => setApikey(e.target.value)}
            />
            <Button onClick={loadVoteData} disabled={!loadButtonActive}>
              개표 정보 로딩
            </Button>
            <InputField
              label="한 표당 개표 간격 (초)"
              type="text"
              placeholder="0.5"
              required
              value={delaySecond}
              onChange={(e) => setDelaySecond(e.target.value)}
            ></InputField>
            <p>
              개표 시간 {(+delaySecond || 0.5) * (totalStudents || 400)}초{" "}
              {totalStudents === 0 && "(추정)"}
            </p>
          </Container>
        )}
      </Section>
    </Container>
  );
};
